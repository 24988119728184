<template>
  <div>
    <el-form :inline="true" class="demo-form-inline">
      <div v-for="(item, index) in revisionListData" :key="index">
        <el-form-item :label="languageText('Rev')">
          {{ index + 1 }}
        </el-form-item>
        <el-form-item :label="languageText('RevisionDate')">
          <el-input
            v-model="item.revision_date"
            :placeholder="languageText('RevisionDate')"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="languageText('RequestedBy')">
          <el-input
            v-model="item.requested_by"
            :disabled="optionButtonFlag(item)"
            :placeholder="languageText('RequestedBy')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="languageText('RevisedNotes')">
          <el-input
            v-model="item.items_revised"
            :disabled="optionButtonFlag(item)"
            :placeholder="languageText('RevisedNotes')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="languageText('EditedBy')">
          <el-input
            v-model="item.edited_by"
            :disabled="optionButtonFlag(item)"
            :placeholder="languageText('EditedBy')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="languageText('Reason')">
          <el-input
            v-model="item.reason"
            :disabled="optionButtonFlag(item)"
            :placeholder="languageText('Reason')"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item :label="languageText('ApprovedBy')">
          <el-input
            v-model="item.approved_by"
            placeholder=""
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 120px">
          <el-button
            type="text"
            @click="completeHandle(item)"
            v-if="showAddRecords()"
            >{{ languageText("Complete") }}</el-button
          >
          &nbsp;&nbsp;
          <!-- <el-button
            type="text"
            v-if="!item.isComplete && revisionList.length > 1"
          >
            <i style="color: Red; font-size: 18px" class="el-icon-delete"></i>
          </el-button> -->
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    pageStatus: String,
    revisionList: {
      type: Array,
      required: true,
    },
    language: String,
  },
  data() {
    return {
      revisionListData:this.revisionList
    };
  },
  watch: {
    revisionListData: {
      handler() {
        this.updateLocalStorage();
      },
      deep: true,
    },
  },
  created() {
    if(this.revisionList.length==0){
      this.revisionListData = [];
      this.completeHandle();
      localStorage.setItem("revision_list",JSON.stringify(this.revisionListData))
    }else{
      this.revisionListData = this.revisionList;
      localStorage.setItem("revision_list",JSON.stringify(this.revisionListData))
    }
    console.warn("this.revisionList：：：：：", this.revisionList);
  },
  methods: {
    updateLocalStorage() {
      try {
        localStorage.setItem("revision_list", JSON.stringify(this.revisionListData));
      } catch (e) {
        console.error("无法将 revisionListData 存储到 localStorage:", e);
      }
    },
    showAddRecords() {
      return true;
    },
    languageText: function (key) {
      let mappingLanage = [
        {
          english: "Rev",
          chinese: "版本",
        },
        {
          english: "RevisionDate",
          chinese: "修订日期",
        },
        {
          english: "RequestedBy",
          chinese: "请求者",
        },
        {
          english: "RevisedNotes",
          chinese: "修订说明",
        },
        {
          english: "EditedBy",
          chinese: "编辑者",
        },
        {
          english: "Reason",
          chinese: "修订原因",
        },
        {
          english: "ApprovedBy",
          chinese: "审核人",
        },
        {
          english: "Complete",
          chinese: "添加修订记录",
        },
      ];

      if (this.language == "CN") {
        let text = mappingLanage.find((item) => item.english == key).chinese;
        return text;
      }
      return key;
    },
    completeHandle() {
      let revision = {
        approved_by: "",
        edited_by: "",
        isComplete: false,
        reason: "",
        requested_by: "",
        items_revised: "",
        revision_date: "",
      };
      this.revisionListData.push(revision);
    },
    optionButtonFlag(item) {
      return item.is_complete;
    },
  },
};
</script>